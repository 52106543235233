import React from "react"
import { graphql } from 'gatsby'

import Layout from "../components/layout"
import { Hero, HeroText } from "../components/Hero"
import { LeftContainer, RightContainer } from "../components/Templates"

import { Contact } from "../components/contact-us"
import SEO from "../components/seo"

const AccompagnementNumerique = ({ data }) => (
  <Layout>
    <SEO title="Accompagnement Numérique" />

    <Hero fluid={data.hero.edges[0].node.fluid}>
      <HeroText text="Accompagnement Numérique"/>
    </Hero>

    <RightContainer
      imageSrc="Saadify/undraw_Devices_re_dxae.png"
      header="Formation aux outils numériques"
      text={[ "Les outils numériques sont devenus un moyen incontournable pour communiquer, promouvoir et gérer son activité. Nous vous proposons de vous accompagner et de vous former aux principaux outils numériques qui vont sont utiles.",
              "Ces derniers vous permettront d'être plus efficace et de vous concentré sur votre activité en laissant les différents logiciels s'occuper des tâches redondants ou chronophage à votre place.",
            ]}
    />

    <LeftContainer
      imageSrc="Saadify/undraw_All_the_data_re_hh4w.png"
      header="Publicité en ligne"
      text = {[ "La publicité en ligne permet d'accroitre la visibilité de votre site web et de votre entreprise auprès d'une clientèle ciblée. Elle peut se faire sur les moteurs de recherche, les réseaux sociaux, ou auprès d'annonceurs.",
                "C'est un outil incontournable qui vous permettra d'atteindre rapidement et efficacement vos futurs clients.",
                "Nous vous proposons un accompagnement afin de promouvoir votre activité en ligne tout en bénéficiant d'un retour quantifiable de l'impact de vos campagnes publicitaires."
               ]}
    />

{/*
gérer google my business
gestion email, mise en place google workspace
formations
coaching

*/}
    <Contact
      contactButton = {[
        "/contact",
        "Contact"
      ]}
    />
  </Layout>
)

export default AccompagnementNumerique

export const pageQuery = graphql`
  query {
    hero: allImageSharp(filter: { original: { src: { regex: "/ecommerce-3562005_1280/" } } }) {
      edges {
        node {
          id
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  }
`;
